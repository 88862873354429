import { Flex, Link, Box } from '@chakra-ui/layout';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PartnerMember } from './PartnerMember';

const Partner = () => {
  const {
    kemper,
    nReher,
    getz,
    ecker,
    igler,
    sell,
    funke,
    lienhop,
    afnb,
    expertenclub,
    afan,
    oexperts,
    wortelkamp,
    doelken,
  } = useStaticQuery(graphql`
    query Partner {
      kemper: file(relativePath: { eq: "Team/kemper.jpeg" }) {
        ...Image
      }
      nReher: file(relativePath: { eq: "04-2022/nReher.png" }) {
        ...Image
      }
      getz: file(relativePath: { eq: "11-2022/getz.jpg" }) {
        ...Image
      }
      ecker: file(relativePath: { eq: "11-2022/ecker.jpg" }) {
        ...Image
      }
      igler: file(relativePath: { eq: "11-2022/igler.jpg" }) {
        ...Image
      }
      sell: file(relativePath: { eq: "11-2022/sell.jpg" }) {
        ...Image
      }
      funke: file(relativePath: { eq: "11-2022/funke.jpg" }) {
        ...Image
      }
      lienhop: file(relativePath: { eq: "11-2022/lienhop.jpg" }) {
        ...Image
      }
      afnb: file(relativePath: { eq: "11-2022/afnb.jpeg" }) {
        ...Image
      }
      bdvt: file(relativePath: { eq: "11-2022/bdvt.jpeg" }) {
        ...Image
      }
      bdvtoe: file(relativePath: { eq: "11-2022/bdvt-oe.jpg" }) {
        ...Image
      }
      expertenclub: file(relativePath: { eq: "11-2022/expertenclub.png" }) {
        ...Image
      }
      afan: file(relativePath: { eq: "11-2022/afan.png" }) {
        ...Image
      }
      oexperts: file(relativePath: { eq: "11-2022/oexperts.jpg" }) {
        ...Image
      }
      wortelkamp: file(
        relativePath: { eq: "11-2024/Wortelkamp_Portrait.jpg" }
      ) {
        ...Image
      }
      doelken: file(relativePath: { eq: "04-2022/bd.jpg" }) {
        ...Image
      }
    }
  `);
  const { t } = useTranslation('team');

  const people = {
    kemper,
    nReher,
    doelken,
    getz,
    ecker,
    igler,
    sell,
    funke,
    lienhop,
    wortelkamp,
  };

  const organizationLinks = [
    { image: expertenclub, url: 'http://www.expertenclub.de/' },
    { image: oexperts },
    { image: afan, url: 'https://afan.academy/', width: '350px' },
    { image: afnb, url: 'http://www.afnb-international.com/' },
  ];

  return (
    <Flex
      as="section"
      flexDir="column"
      align="center"
      px="10px"
      py={['20px', '50px']}
    >
      <Flex w={['100%', '80%']} justify="center" flexWrap="wrap">
        {Object.keys(people).map(key => (
          <PartnerMember
            key={key}
            image={getImage(people[key])}
            mt="0"
            name={t(`${key}.name`)}
            position={t(`${key}.position`)}
            description={t(`${key}.description`)}
            linkedInLink={t(`${key}.linkedin`)}
            expandText={t(`${key}.expandText`)}
          />
        ))}
      </Flex>
      <Flex w={['100%', '80%']} justify="center" flexWrap="wrap" align="center">
        {organizationLinks.map(({ image, url, width = '200px' }, idx) => (
          <Box key={idx} w={width} mx="1rem" mb="1rem">
            {url ? (
              <Link href={url} target="__blank">
                <GatsbyImage
                  image={getImage(image) as IGatsbyImageData}
                  alt="Header"
                  style={{
                    height: '100%',
                    width: '100%',
                  }}
                  objectFit="contain"
                  objectPosition="center"
                />
              </Link>
            ) : (
              <GatsbyImage
                image={getImage(image) as IGatsbyImageData}
                alt="Header"
                style={{
                  width: '100%',
                }}
                objectFit="contain"
                objectPosition="center"
              />
            )}
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};

export default Partner;
