import { Box, Link } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/layout';
import { Heading } from '@chakra-ui/layout';
import { Flex } from '@chakra-ui/layout';
import { Collapse } from '@chakra-ui/react';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReadMoreBtn from './ReadMoreBtn';

export interface ITeamMember {
  name: string;
  position?: string;
  description: string;
  expandText?: string;
  image: any;
  linkedInLink: string;
  [key: string]: any;
}
export const TeamMember = ({
  name,
  position,
  description,
  image,
  expandText,
  linkedInLink,
  ...props
}: ITeamMember) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Flex
      as="article"
      mt="25px"
      w={['100%', 'clamp(500px, 1200px, 1400px)']}
      flexWrap={['wrap', 'nowrap']}
      {...props}
    >
      <Flex flexBasis={['100%', 'auto']} justifyContent="center">
        <Box
          minW={['100px', '300px']}
          maxW={['400px', 'auto']}
          px={'20px'}
          height={['300px', '400px']}
          mr={['0', '20px']}
          mb={['20px', '0']}
        >
          <GatsbyImage
            image={image}
            alt="Header"
            style={{
              height: '100%',
              width: '100%',
            }}
            objectFit="cover"
            objectPosition="top"
          />
        </Box>
      </Flex>
      <Box>
        <Heading
          fontFamily="Courier"
          color="brand.600"
          fontSize="25px"
          fontWeight="600"
          as="h2"
        >
          {name}
        </Heading>
        {position && (
          <Text fontSize="25px" fontWeight="600">
            {position}
          </Text>
        )}
        <Text
          fontSize="18px"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {expandText && (
          <Box>
            <ReadMoreBtn isOpen={isOpen} onToggle={toggleOpen} />
            <Collapse in={isOpen}>
              <Text
                fontSize="18px"
                dangerouslySetInnerHTML={{ __html: expandText }}
              />
            </Collapse>
          </Box>
        )}
        {linkedInLink && (
          <Box mt="10px">
            <Link href={linkedInLink} target="__blank">
              <FontAwesomeIcon style={{ fontSize: '24px' }} icon={faLinkedin} />
            </Link>
          </Box>
        )}
      </Box>
    </Flex>
  );
};

const Team = () => {
  const { reher, schroeder } = useStaticQuery(graphql`
    query Team {
      reher: file(relativePath: { eq: "03-2024/0838-web.jpg" }) {
        ...Image
      }
      schroeder: file(relativePath: { eq: "03-2024/KS-1427-print1.jpeg" }) {
        ...Image
      }
    }
  `);
  const { t } = useTranslation('team');
  return (
    <Flex
      as="section"
      flexDir="column"
      alignItems="center"
      px="10px"
      py={['20px', '50px']}
    >
      <TeamMember
        image={getImage(reher)}
        mt="0"
        name={t('reher.name')}
        position={t('reher.position')}
        description={t('reher.description')}
        expandText={t('reher.expandText')}
        linkedInLink={t('reher.linkedin')}
      />
      <TeamMember
        image={getImage(schroeder)}
        name={t('schroeder.name')}
        position={t('schroeder.position', '')}
        description={t('schroeder.description')}
        expandText={t('schroeder.expandText')}
        linkedInLink={t('schroeder.linkedin')}
      />
    </Flex>
  );
};

export default Team;
