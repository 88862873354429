import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faYoutubeSquare,
  faVimeoSquare,
  faLinkedinIn,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

import Hero from '../components/Hero';
import OnePager from '../layouts/OnePager';
import SEO from '../components/Seo';
import TextBlock from '../components/TextBlock';
import HeaderBar, { headerHeightInPx } from '../components/HeaderBar';
import { Text } from '@chakra-ui/layout';
import { Link } from '@chakra-ui/layout';
import { Center } from '@chakra-ui/layout';
import Maps from '../components/Maps';
import { Flex } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/layout';
import Competencies from '../components/Competencies';
import Team from '../components/Team';
import Footer from '../components/Footer';
import Partner from '../components/Partner';
import { GeneralNavbar } from '../components/GeneralNavbar';
import { ScrollButton } from '../components/ScrollButton';

const LogoIcon = ({ icon }) => (
  <Box
    fontSize="80px"
    color="brand.400"
    _hover={{ color: 'brand.600', cursor: 'pointer' }}
    flexBasis={['50%', 'auto']}
    mx={[0, '10px']}
    mb={['20px', 0]}
  >
    <Center>
      <FontAwesomeIcon height="100%" width="100%" icon={icon} />
    </Center>
  </Box>
);

const Home = ({
  data: { hero1, hero2, hero3, hero4, hero5, hero6, partner },
}) => {
  const { t } = useTranslation();
  const refs = useRef(Array(5).fill(null));
  const [currentRefs, setRefs] = useState(refs.current);

  useEffect(() => {
    setRefs([...refs.current]);
  }, refs.current);

  return (
    <Box width="100vw" overflow="hidden">
      <SEO title="brainCheck" />
      <HeaderBar refs={currentRefs} />
      <ScrollButton />
      <Hero
        imgSrc={hero1}
        isFirstOne
        heading={t('hero:firstHero.heading')}
        text={t('hero:firstHero.subheading')}
        ctaText={t('hero:firstHero.cta')}
        copyright={t('hero:firstHero.copyright')}
        isUnsplash
        imgPos="10%"
        filter="brightness(0.7)"
      />
      <TextBlock
        text={t('text:first.text')}
        heading={t('text:first.heading')}
      />
      <Hero
        imgSrc={hero2}
        heading={t('hero:second.heading')}
        text={t('hero:second.subheading')}
        copyright={t('hero:second.copyright')}
        isUnsplash
      />
      <TextBlock
        text={t('text:second.text')}
        heading={t('text:second.heading')}
      />
      <TextBlock
        style={{ paddingTop: 0 }}
        text={t('text:secondTwo.text')}
        heading={t('text:secondTwo.heading')}
      />
      <Competencies ref={el => (refs.current[0] = el)} />
      <GeneralNavbar refs={currentRefs} />
      <Hero
        ref={el => (refs.current[1] = el)}
        imgSrc={hero3}
        heading={t('hero:third.heading')}
        text={t('hero:third.subheading')}
        imgPos="center"
        boxProps={{
          height: ['400px', `calc(100vh - ${headerHeightInPx}px)`],
        }}
        textBg
      />
      <TextBlock text={t('text:third.text')}>
        <Box mt="10px">
          <Link
            href="https://www.linkedin.com/company/braincheck-gmbh/?viewAsMember=true"
            target="__blank"
          >
            <FontAwesomeIcon
              style={{ fontSize: '24px', color: 'white' }}
              icon={faLinkedin}
            />
          </Link>
        </Box>
      </TextBlock>
      <Team />
      <Hero
        ref={el => (refs.current[3] = el)}
        imgSrc={partner}
        heading={t('hero:partner.heading')}
        text={t('hero:partner.subheading')}
        imgPos="top"
        boxProps={{
          height: ['400px', `calc(100vh - ${headerHeightInPx}px)`],
        }}
        textBg
      />
      <Partner />
      <GeneralNavbar refs={currentRefs} />
      <TextBlock
        heading={t('text:forth.heading')}
        ctaText={t('text:forth.ctaText')}
      />
      <Hero
        imgSrc={hero4}
        imgPos="top"
        heading={t('hero:forth.heading')}
        text={t('hero:forth.subheading')}
        textBg
      />
      <TextBlock
        heading={t('text:fifth.heading')}
        text={t('text:fifth.text')}
        ref={el => (refs.current[2] = el)}
        expandComponent={
          <Box>
            <Text
              fontSize={['16px', '20px']}
              color="brand.400"
              dangerouslySetInnerHTML={{ __html: t('text:fifth.expandText') }}
            />
            <Box
              bg="brand.400"
              fontStyle="italic"
              p="50px"
              mt="40px"
              borderRadius="40px"
            >
              <Text
                fontSize={['16px', '20px']}
                color="brand.500"
                fontWeight="300"
                dangerouslySetInnerHTML={{
                  __html: t('text:fifth.expandTestimonials'),
                }}
              ></Text>
            </Box>
          </Box>
        }
      />
      {/*  hide Shop Products
      <Hero
        ref={el => (refs.current[3] = el)}
        imgSrc={hero5}
        heading={t('hero:fifth.heading')}
        text={t('hero:fifth.subheading')}
        textBg
      />
      <TextBlock
        text={t('text:sixth.text')}
        heading={t('text:sixth.heading')}
        expandBg="brand.400"
        expandComponent={
          <Box bg="brand.400">
            <Products />
          </Box>
        }
      /> */}
      <Hero
        imgSrc={hero6}
        text={t('hero:sixth.subheading')}
        heading={t('hero:sixth.heading')}
        textBg
      />
      <TextBlock
        ref={el => (refs.current[4] = el)}
        /* text={
          <>
            <Flex
              fontSize={['16px', '33px']}
              color="brand.400"
              flexDir="column"
            >
              <Text
                dangerouslySetInnerHTML={{ __html: t('text:seventh.text') }}
              />
              <Link href="tel:+49 (0)221 99878648">+49 (0)221 99878648</Link>
              <Link href="mailto:happy@braincheck.de" target="_blank">
                happy@braincheck.de
              </Link>
              <Link target="_blank" href="https://facebook.com/braincheckGmbH/">
                Facebook
              </Link>
            </Flex>
          </>
        } */
        text={t('text:seventh.text')}
        heading={t('text:seventh.heading')}
      />
      <TextBlock
        heading={t('text:eigth.heading')}
        text={
          <Center mt="30px">
            <Maps height="400px" width="100%" />
          </Center>
        }
      />
      {/* <TextBlock
        forceCenter
        heading={t('text:nineth.heading')}
        text={
          <Flex mt="30px" justifyContent="center" flexWrap="wrap">
            <LogoIcon icon={faFacebookSquare} />
            <LogoIcon icon={faYoutubeSquare} />
            <LogoIcon icon={faVimeoSquare} />
            <LogoIcon icon={faLinkedinIn} />
          </Flex>
        }
      /> */}
      <Footer />
    </Box>
  );
};

/*
neue bilder:
hero3: file(relativePath: { eq: "11-2024/team.jpg" }) {
partner: file(relativePath: { eq: "11-2024/Netzwerkslider.jpg" }) {
*/
export const query = graphql`
  query ($language: String!) {
    hero1: file(relativePath: { eq: "v3/use-your-brain.jpg" }) {
      ...Image
    }
    hero2: file(
      relativePath: { eq: "pavan-trikutam-71CjSSB83Wo-unsplash.jpg" }
    ) {
      ...Image
    }
    hero3: file(relativePath: { eq: "04-2022/Teamphoto.jpg" }) {
      ...Image
    }
    partner: file(relativePath: { eq: "04-2022/puppen.jpeg" }) {
      ...Image
    }
    hero4: file(relativePath: { eq: "v3/feedback.jpg" }) {
      ...Image
    }
    hero5: file(relativePath: { eq: "v3/bc_produkte.jpg" }) {
      ...Image
    }
    hero6: file(relativePath: { eq: "09-2024/bc-internetseite-match-v2.png" }) {
      ...Image
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
  fragment Image on File {
    childImageSharp {
      gatsbyImageData(
        height: 1200
        quality: 70
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
`;

export default Home;
